<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          class="executions-col"
        >
          <v-card-title class="px-0 pt-0">
            User Consumption (Top
            <div
              class="ml-3 mr-3"
              style="width: 90px"
            >
              <v-select
                v-model="numberOfUsersShown"
                :items="numberOfUsersShownOptions"
                outlined
                small
                dense
                hide-details
              ></v-select>
            </div>Consumers)
            <v-spacer></v-spacer>
            <div style="width:300px">
              <date-range-selector></date-range-selector>
            </div>
          </v-card-title>
          <vue-apex-charts
            id="executions-chart"
            height="320"
            :options="chartOptions"
            :series="chartData"
          ></vue-apex-charts>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import DateRangeSelector from '@/components/global/DateRangeSelector.vue'
import { colorLight, formatNumbers } from '@/functions'
import store from '@/store'
import { getVuetify } from '@core/utils'
import { kFormatter } from '@core/utils/filter'
import { computed, ref } from '@vue/composition-api'
import _ from 'lodash'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    DateRangeSelector,
  },
  setup(props, context) {
    // const dateRangeFilterSelection = computed(() => store.getters.dateRangeFilter)
    // const snowflakeContractDetails = computed(() => store.state.snowflakeData.snowflakeContractDetails)
    const numberOfUsersShown = ref(25)
    const numberOfUsersShownOptions = [
      { text: '10', value: 10 },
      { text: '25', value: 25 },
      { text: '50', value: 50 },
      { text: '100', value: 100 },
      { text: 'All', value: 1000 },
    ]
    const userExecutions = computed(() => {
      const getTotalsByFilter = store.getters['snowflakeData/snowflakeExecutionsByFilter']
      let totalsByFilter = [...getTotalsByFilter]

      totalsByFilter = totalsByFilter.map(m => {
        if (!m.wc) {
          return { ...m, wc: 0 }
        }

        return { ...m }
      })
      totalsByFilter = _(totalsByFilter)
        .groupBy('user')
        .map((m, user) => ({
          user,
          ex: _.sumBy(m, 'ex'),
          ee: _.sumBy(m, 'ee'),
          du: _.sumBy(m, 'du'),
          wc: _.sumBy(m, 'wc'),
        }))
        .value()
      totalsByFilter = _.orderBy(totalsByFilter, 'wc', 'desc')

      if (store.state.showSampleData) {
        totalsByFilter.forEach((row, i) => {
          const userMatch = store.state.snowflakeData.snowflakeUsers.filter(f => f.id === row.user)[0]
          if (userMatch) totalsByFilter[i].sampleName = userMatch.loginName
        })
        totalsByFilter = totalsByFilter.filter(f => f.user !== 'HEVO')
      }

      // Filter Top...
      totalsByFilter = totalsByFilter.slice(0, numberOfUsersShown.value)
      const users = totalsByFilter.map(m => m.sampleName || m.user)
      const successfulExecutions = totalsByFilter.map(m => m.ex - (m.ee || 0) || 0)
      const failedExecutions = totalsByFilter.map(m => m.ee || 0)
      const duration = totalsByFilter.map(m => m.du || 0)
      const credits = totalsByFilter.map(m => Math.round(m.wc * 100) / 100 || 0)

      return {
        successfulExecutions,
        failedExecutions,
        duration,
        credits,
        users,
      }
    })

    const $vuetify = getVuetify()

    const chartData = computed(() => [
      {
        name: 'Successful Executions',
        type: 'column',

        data: userExecutions.value.successfulExecutions,
      },
      {
        name: 'Failed Executions',
        type: 'column',
        data: userExecutions.value.failedExecutions,
      },
      {
        name: 'Execution Time',
        type: 'line',
        data: userExecutions.value.duration,
      },
      {
        name: 'Approx Credits Consumed',
        type: 'area',
        data: userExecutions.value.credits,
      },
    ])

    const chartOptions = computed(() => ({
      colors: [colorLight($vuetify.theme.currentTheme.success), colorLight($vuetify.theme.currentTheme.error), $vuetify.theme.currentTheme.warning, colorLight($vuetify.theme.currentTheme.primary, 0.1)],
      chart: {
        height: 350,
        type: 'line',
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '60%',
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [0, 1],
        formatter: val => formatNumbers(val),
        style: {
          fontSize: '12px',
          colors: [$vuetify.theme.currentTheme.success, $vuetify.theme.currentTheme.error],
        },
      },
      stroke: {
        colors: ['white', 'white', $vuetify.theme.currentTheme.warning, $vuetify.theme.currentTheme.primary],
        width: [1, 1, 4, 4],
      },
      xaxis: {
        categories: userExecutions.value.users,
      },
      yaxis: [
        {
          seriesName: 'Successful Executions',
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: $vuetify.theme.currentTheme.success,
          },
          labels: {
            formatter: value => kFormatter(value, 0),
            style: {
              colors: $vuetify.theme.currentTheme.success,
            },
          },
          title: {
            text: 'Executions',
            style: {
              color: $vuetify.theme.currentTheme.success,
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        {
          seriesName: 'Successful Executions',
          show: false,
          labels: {
            formatter: value => kFormatter(value, 0),
          },
          title: {
            text: 'Failed Executions',
            style: {
              color: '#00E396',
            },
          },
        },
        {
          seriesName: 'Execution Time',
          show: false,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: $vuetify.theme.currentTheme.warning,
          },
          labels: {
            formatter: value =>
              // eslint-disable-next-line implicit-arrow-linebreak
              context.root.$moment.duration(value, 'milliseconds').humanize({
                s: 60,
                m: 60,
                h: 24,
                d: 7,
                w: 4,
                M: 12,
              }),
            style: {
              colors: $vuetify.theme.currentTheme.warning,
            },
          },
        },
        {
          seriesName: 'Approx Credits Consumed',
          opposite: true,
          show: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          labels: {
            style: {
              colors: '#9155FD',
            },
          },
          title: {
            text: 'Approx Credits Consumed',
            style: {
              color: $vuetify.theme.currentTheme.primary,
            },
          },
        },
      ],
      tooltip: {
        fixed: {
          enabled: true,
          position: 'bottomRight', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 0,
          offsetX: 0,
        },
      },
      legend: {
        show: true,
        horizontalAlign: 'left',
        offsetX: 40,
        position: 'top',
      },
      responsive: [
        {
          breakpoint: 1650,
          options: {
            plotOptions: {
              bar: {
                borderRadius: 10,
                columnWidth: '45%',
              },
            },
          },
        },
      ],
    }))

    return {
      chartOptions,
      chartData,
      numberOfUsersShown,
      numberOfUsersShownOptions,
      icons: {},
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';
@import '~vuetify/src/styles/styles.sass';
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .executions-chart-col {
    @include ltr() {
      border-right: thin solid;
    }
    @include rtl() {
      border-left: thin solid;
    }
  }
}
@include theme--child(executions-chart-col) using ($material) {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    border-color: map-get($material, 'dividers');
  }
}
</style>

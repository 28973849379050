<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New User</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-user-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <v-text-field
            v-model="userData.username"
            :rules="[validators.required]"
            outlined
            dense
            label="Snowflake Username"
            placeholder="Username"
            hide-details="auto"
            class="mb-6"
            hint="Use caution with the USERNAME. The username can include spaces and non-alphanumeric characters ( . ! % * ) but they must ALWAYS be wrapped in double quotes and will be case sensitive."
            persistent-hint
          ></v-text-field>

          <v-text-field
            v-model="userData.firstName"
            outlined
            dense
            :rules="[validators.required]"
            label="First Name"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="userData.lastName"
            outlined
            dense
            :rules="[validators.required]"
            label="Last Name"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="userData.email"
            :rules="[validators.required,validators.emailValidator]"
            outlined
            dense
            type="email"
            label="Email"
            placeholder="Email"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="userData.password"
            :rules="[validators.required]"
            outlined
            dense
            type="password"
            label="Password"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-checkbox
            v-model="userData.tempPassword"
          >
            <template v-slot:label>
              <span class="caption">Require User To Change Password at Login?</span>
            </template>
          </v-checkbox>

          <v-select
            v-model="userData.role"
            :rules="[validators.required]"
            label="Default Role"
            :items="roleOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
          >
          </v-select>

          <v-select
            v-model="userData.warehouse"
            :rules="[validators.required]"
            label="Default Warehouse (Compute)"
            :items="warehouseOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
            hint="Make sure the Warehouse is allowed by the specified ROLE"
            persistent-hint
          >
          </v-select>

          <v-text-field
            v-model="userData.namespace"
            outlined
            dense
            label="Default Namespace"
            hide-details="auto"
            class="mb-6"
            hint="[OPTIONAL]: i.e. database.schema.table"
            persistent-hint
          ></v-text-field>

          <v-checkbox
            v-model="userData.createAppUser"
            :label="'Create ' + serverConfig.name + ' Account'"
          >
          </v-checkbox>

          <v-alert
            v-if="formError"
            type="error"
          >
            {{ formError }}
          </v-alert>

          <v-btn
            color="primary"
            class="me-3"
            type="submit"
          >
            Add
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="resetuserData"
          >
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import store from '@/store'
import { emailValidator, required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import serverConfig from '@serverConfig'
import { computed, ref } from '@vue/composition-api'
import { createSnowflakeUser } from '../../../snowflake/snowflakeAdministration'

export default {
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      username: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      role: null,
      tempPassword: true,
      warehouse: null,
      namespace: null,
    }

    const formError = ref(null)

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const warehouseOptions = computed(() => {
      const warehouses = store.state.snowflakeData.snowflakeWarehouses.map(m => m.name)

      return warehouses
    })

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      formError.value = null
      userData.value = JSON.parse(JSON.stringify(blankUserData))
      resetForm()
      emit('update:is-add-new-user-sidebar-active', false)
    }

    const onSubmit = async () => {
      formError.value = null

      // Check if username includes incorrectly formatted values
      const evaluateUsername = userData.value.username.replace('_', '') // Underscores are OK
      console.log('USERNAME', evaluateUsername)
      const firstCharacter = evaluateUsername[0]
      const lastCharacter = evaluateUsername[evaluateUsername.length - 1]
      if (firstCharacter.match(/^[0-9]+$/)) {
        formError.value = 'USERNAME cannot begin with a number'

        return
      }
      if (!evaluateUsername.match(/^[0-9a-zA-Z]+$/)) {
        if (!(firstCharacter === '"' && lastCharacter === '"')) {
          console.log('NON CHAR FOUND')
          formError.value = `If non-alphanumeric characters are used in the username -
                          the USERNAME MUST be wrapped in DOUBLE QUOTES ""`

          return
        }
      }
      if (valid.value) {
        try {
          const sfResponse = await createSnowflakeUser(userData.value)
          console.log('sfResponse', sfResponse)
        } catch (err) {
          store.dispatch('systemMessages', `<strong>${err}</strong>`)
        }

        // emit('update:is-add-new-user-sidebar-active', false)

        resetuserData()
      } else {
        validate()
      }
    }

    return {
      serverConfig,
      formError,
      warehouseOptions,
      resetuserData,
      form,
      onSubmit,
      userData,
      valid,
      validate,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
